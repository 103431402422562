import styled from "styled-components";
export const Main = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-basis: 100%;

  gap: 2rem;
  flex-direction: column;
  justify-content: space-around;
  @media screen and (max-width: 970px) {
    margin-top: -100px;
    height: 155vh;
  }
`;
export const Main2 = styled.div`
  width: 100%;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-basis: 100%;
  gap: 2rem;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 970px) {
    flex-direction: column;
    height: 100%;

    justify-content: center;
    align-items: center;
  }
`;
export const Continer = styled.div`
  text-align: center;
  display: flex;
  height: 50vh;
  justify-content: center;
  flex: 1;
  z-index: 1;
  flex-shrink: 1;
  flex-direction: column;
  @media screen and (max-width: 970px) {
    height: 100%;
    width: 70%;
  }
`;
export const Vid = styled.iframe`
  height: 30vh;
  box-shadow: 0px 0px 5px 6px grey;
  width: 96%;
  @media screen and (max-width: 970px) {
    height: 100%;
    width: 100%;
  }
`;
export const Spacemedia = styled.div`
  height: 0px;
  width: 0px;

  @media screen and (max-width: 970px) {
    height: 30px;
    width: 0px;
  }
`;
