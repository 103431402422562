import styled from "styled-components";
export const ContactForm = styled.form`
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  text-align: center;
  z-index: 1;
  align-items: center;
  color: #979797;
  @media screen and (max-width: 970px) {
    height: 100vh;
    flex-direction: column;
  }
`;
export const Contactrow = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  z-index: 1;

  text-align: center;
  align-items: center;
  @media screen and (max-width: 970px) {
    width: 100%;
  }
`;
export const Input = styled.input`
  width: 40%;
  height: 40px;
  border: 2px solid;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 2.5px grey;
`;
export const Select = styled.select`
  width: 40%;
  height: 40px;
  border: 2px solid;
  border-radius: 4px;
`;
export const ContactHead = styled.div`
  color: white;
  text-align: center;
  font-size: 3rem;
  line-height: 1.1;
  z-index: 1;
  font-weight: 600;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
export const Contactspace = styled.div`
  height: 50px;
`;

export const Dialogurl = styled.div`
  position: fixed;
  bottom: -15rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  width: 80%;
  background-color: grey;
  font-size: 12px;

  z-index: 9;
  right: 10%;

  bottom: 10%;

  @media screen and (max-width: 1100px) {
    position: fixed;
    z-index: 9;
    right: 10%;

    bottom: 10%;
    font-size: 12px;
  }
  @media screen and (max-width: 960px) {
    position: fixed;
    z-index: 9;
    right: 10%;

    bottom: 10%;
    font-size: 7px;
  }
`;
export const Dialogurl2 = styled.div`
  position: relative;
  bottom: -15rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  width: 80%;
  background-color: grey;
  z-index: 9;
  @media screen and (max-width: 960px) {
    position: fixed;
    z-index: 9;
    right: 1rem;

    bottom: 50%;
    font-size: 15px;
  }
`;
export const Contatctterms = styled.button`
  color: white;
  text-decoration: underline;
`;
