import React, { useEffect } from "react";
import {
  Heroimg,
  Mvideo,
  Pherosection,
} from "./SportsConsultingDepartmentsstyles";
import img1 from "../components/assets/FB_IMG_1704400360670.jpg";
import img2 from "../components/assets/FB_IMG_1704400404691.jpg";
import img3 from "../components/assets/4.png";
import img4 from "../components/assets/FB_IMG_1704400404691.jpg";
import img5 from "../components/assets/t2024.jpg";

import hero from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import {
  Backgroundimg,
  Head,
  Image1,
  Image2,
  Image3,
  Lefttop,
  Rightbottom,
  Space,
  Row,
  Underline,
  Image4,
  Image44,
  BSpace,
  Divider,
} from "./SportsConsultingDepartmentsstyles";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
export const Vid = styled(motion.iframe)`
  width: 85%;
  height: 50vh;
  border-radius: 2px;
  box-shadow: -10px -10px 2px grey;

  @media screen and (max-width: 970px) {
    height: 100%;
    width: 100%;
  }
`;
function Tournamnet2024() {
  const initial = { opacity: 0, y: 50 };
  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      console.log("sss");
      animation.start({
        opacity: 1,
        y: 1,
      });
    }
  }, [inView, animation]);
  return (
    <div>
      {" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Mvideo
          src="https://www.youtube.com/embed/6Dkz3vqDMVE"
          title="Training Camp (OffSeason]"
          frameborder="0"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      </Pherosection>
      <Divider />{" "}
      <Pherosection>
        <Heroimg src={hero} /> <Space />
        <Rightbottom>
          <Row>
            <Row>
              <Space />
              <Image2 src={img1} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image2 src={img2} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {"Elite Tournament"}</Head>
          <Underline />
          {
            "A Football Training Camp In Addition To Participating The Strongest And Largest Championship In Spain And Europe for age group under 18 ."
          }
        </Lefttop>
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {"Expert Trainers"}</Head>
          <Underline />
          {
            "The camp is under the supervision of a highly qualified team with great experience in the field of developing players.The team will refine their talents, and work to prepare them psychologically, mentally, and physically to participate in the tournament, which includes the largest and strongest clubs and international academies."
          }
        </Lefttop>{" "}
        <Rightbottom ref={ref}>
          <Vid
            initial={initial}
            transition={{ delay: 0.1, duration: 0.6 }}
            animate={animation}
            whileHover={{ rotate: 2, scale: 1.02 }}
            src="https://www.youtube.com/embed/Q6n8GWSXN4c"
            title="Off Season 2022 (Nico González)"
            frameborder="0"
            allowFullScreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          ></Vid>{" "}
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        <Heroimg src={hero} /> <Space />
        <Rightbottom>
          <Row>
            <Row>
              <Space />
              <Image1 src={img5} />
            </Row>
          </Row>{" "}
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {"Teams involved"}</Head>
          <Underline />
          {
            "A formidable team of passionate professionals, bound by their expertise, dedication, and camaraderie, igniting the spirit of competition in our tournament, leaving a lasting impression"
          }
        </Lefttop>
      </Pherosection>{" "}
    </div>
  );
}

export default Tournamnet2024;
