import React, { useEffect, useState } from "react";
import { Container, Section } from "../../globalStyles";
import {
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  ContentButton,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
  Blur,
} from "./ContentStyles.js";
import hero from "../assets/c31a51da7dd939423c2eedbf7e726765.jpg";

import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import {
  BSpace,
  Heroimg,
  Space,
} from "../../pages/SportsConsultingDepartmentsstyles";

export const Content = ({
  primary,
  topLine,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  inverse,
  reverse,
}) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();

  const { ref, inView } = useInView({ threshold: 0.2 });
  var dataE = [
    "Founded in 2023",
    "About Us",
    "Our team provides specialized and personal consultations to athletes, clubs, federations, and sports companies with the goal of finding appropriate solutions to meet the different needs and goals of each of our clients.Our methodology has been developed through experience, scientific study, modern and innovative technology, and our teams special knowledge and skills.",
    "Find More",
  ];
  var dataA = [
    "تأسست عام 2023",
    "نبذة عنا",
    " يقوم فريقنا بتقديم الاستشارات المتخصصة والمستقلة للرياضيين والأندية والاتحادات وشركات القطاع الرياضي بهدف إيجاد الحلول المناسبة لاحتياجاتهم وتميزهم.  تعتمد منهجيتنا على الخبرة والدراسة العلمية والتكنولوجيا الحديثة والمبتكرة والمعارف والمهارات الخاصة التي يتميز بها فريق عملنا.",
    "المزيد",
  ];
  const [data, setDatas] = useState(dataE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(dataA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);
  useEffect(() => {
    if (inView) {
      console.log("fi");
      animation.start({
        opacity: 1,
        y: 1,
      });
    }
  }, [inView, animation]);

  return (
    <Section inverse={inverse} ref={ref}>
      <Heroimg src={hero} />

      <Container>
        <ContentRow reverse={reverse}>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                {data[0]}
              </TopLine>
              <Heading
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={inverse}
              >
                {" "}
                {data[1]}
              </Heading>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={inverse}
              >
                {data[2]}
              </Subtitle>
              <ContentButton
                initial={initial}
                transition={{ delay: 1, duration: 0.6 }}
                animate={animation}
                inverse={inverse}
                primary={primary}
              >
                {data[3]}
              </ContentButton>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          >
            <ImgWrapper>
              <Img
                src={img}
                alt={alt}
                whileHover={{ rotate: 2, scale: 1.02 }}
                transition={{ duration: 0.5 }}
              />
            </ImgWrapper>
          </ContentColumn>
        </ContentRow>
      </Container>
      <Blur></Blur>
    </Section>
  );
};
