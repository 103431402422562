import React, { useEffect, useState } from "react";
import {
  Backgroundimg,
  Head,
  Image1,
  Image2,
  Image3,
  Lefttop,
  Mvideo,
  Pherosection,
  Heroimg,
  Rightbottom,
  Space,
  Row,
  Underline,
  Image4,
  Image44,
  BSpace,
  Divider,
} from "./SportsConsultingDepartmentsstyles";
import img1 from "../components/assets/IMG-20221211-WA0093.jpg";
import img2 from "../components/assets/IMG-20221213-WA0004.jpg";
import img3 from "../components/assets/IMG-20221213-WA0023.jpg";
import img4 from "../components/assets/IMG-20221213-WA0028.jpg";
import img5 from "../components/assets/IMG-20221211-WA0070.jpg";
import img6 from "../components/assets/IMG-20221211-WA0071.jpg";
import img7 from "../components/assets/IMG-20221211-WA0085.jpg";
import img8 from "../components/assets/IMG-20221211-WA0078.jpg";
import img9 from "../components/assets/IMG-20221211-WA0095.jpg";
import img10 from "../components/assets/IMG-20221211-WA0088.jpg";
import img11 from "../components/assets/IMG-20221211-WA0091.jpg";
import img12 from "../components/assets/IMG-20221213-WA0029.jpg";
import img13 from "../components/assets/IMG-20221213-WA0013.jpg";
import img14 from "../components/assets/IMG-20221213-WA0014.jpg";
import img15 from "../components/assets/IMG-20221213-WA0016.jpg";
import hero from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";

function AcademyCamp() {
  var descE = [
    "Academy Camp",
    "Experience an elite camp with players from FC Barcelona and the Spanish national team. Supervised by a highly qualified team with experience refining the talents of developing players with the goal of reaching the highest professional level. For players under the age of 18.",
    "Camp Includes",
    "Supervision of the entire sports training camp from arrival to departure ,Natural grass pitches",
    "Private Transportation , Living ",
    " Private transportation for all trips",
    "Accommodation for the players ",
    "Meals , Activites",
    "Full and special meals for the athletes according to Islamic law ",
    " Organization of recreational, sports and tourism activities in Barcelona, including a visit to the FC Barcelona stadium and the clubs historical museum",
    "Live Experience ",
    "- Receipt of a personal video and photos of the player about their entire experience at the camp ",
    "The player will receive the official FC Barcelona jersey with signatures of the players present at the camp",
  ];
  var descA = [
    "معسكر الأكاديمية",
    "معسكر وتجمع نخبوي بحضور وتواجد عدد من لاعبي نادي برشلونة والمنتخب الإسباني، موجّه لجميع الفئات العمرية ما دون الثمانية عشر تحت إشراف فريق عمل مؤهل تأهيلاً عالياً وذي خبرة كبيرة في مجال تطوير اللاعبين وصقل مواهبهم للوصول إلى أعلى مستوى احترافي، بالإضافة لوجود كادر مختص لاكتشاف المواهب وخلق الفرصة لهم لبداية طريق احترافي في إسبانيا ",
    "المعسكر يتضمن",
    "التكفل بكل ما يتعلق بالمعسكر التدريبي الرياضي منذ لحظة الوصول حتى المغادرة,   ملاعب العشب الطبيعي",
    "السكن و التنقلات ",
    " وسائل النقل الخاصة لتغطية جميع التنقلات ",
    "  السكن المخصص لللاعبين كرة القدم",
    " الغذاء و الأنشطة",
    " الوجبات الغذائية الكاملة والخاصة للرياضين حسب الشريعة الإسلامية ",
    " تنظيم الأنشطة الترفيهية والرياضية والسياحية في برشلونة، من ضمنها زيارة ملعب برشلونة والمتحف التاريخي للناد",
    " عيش التجربة",
    "الحصول على فيديو شخصي وصور اللاعب لكامل تجربته في المعسكر ",
    "حصول اللاعب على القميص الرسمي لنادي برشلونة موقع من اللاعبين المتواجدين",
  ];
  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);
  return (
    <div>
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Mvideo
          src="https://www.youtube.com/embed/tetBhXvUYUI"
          title="Academy Camp"
          frameborder="0"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        <Heroimg src={hero} /> <Space />
        <Rightbottom>
          <Row>
            <Row>
              <Space />
              <Image2 src={img1} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image2 src={img2} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[0]}</Head>
          <Underline />
          {data[1]}
        </Lefttop>
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[2]}</Head>
          <Underline />
          {data[3]}
        </Lefttop>{" "}
        <Rightbottom>
          <Row>
            <Row>
              <Space />
              <Image2 src={img3} />
            </Row>
          </Row>
          <Space />

          <Row>
            <Row>
              {" "}
              <Image2 src={img4} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Rightbottom>
          <Row>
            <Row>
              {" "}
              <Space />
              <Image2 src={img5} />
            </Row>
          </Row>
          <Space />

          <Row>
            <Row>
              {" "}
              <Image2 src={img6} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[4]}</Head>
          <Underline />
          {data[5]}
          <br />
          <br />
          {data[6]}
        </Lefttop>
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[7]}</Head>
          <Underline />
          {data[8]}
          <br />
          <br /> {data[9]}
        </Lefttop>{" "}
        <Rightbottom>
          <Row>
            <Row>
              {" "}
              <Image44 src={img8} /> <Space />
              <Image44 src={img9} />
            </Row>
          </Row>
          <Space />

          <Row>
            <Row>
              {" "}
              <Image4 src={img7} />
              <Space />
              <Image4 src={img10} /> <Space />
              <Image4 src={img11} />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Rightbottom>
          <Row>
            <Row>
              {" "}
              <Image2 src={img12} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image4 src={img13} />
              <Space /> <Image4 src={img14} />
              <Space />
              <Image4 src={img15} />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[10]}</Head>
          <Underline />
          {data[11]}
          <br /> {data[12]}
        </Lefttop>
      </Pherosection>{" "}
      <BSpace />
      <BSpace />
      <BSpace />
    </div>
  );
}
export default AcademyCamp;
