import React from "react";
import { Link } from "react-router-dom";

import { Button, Container, MainHeading } from "../../globalStyles";
import {
  HeroVideo,
  HeroSection,
  HeroText,
  ButtonWrapper,
  Iconnn,
  HeroButton,
} from "./HeroStyles";
import "./Hero.css";
import icon from "../assets/sound-waves.png";
import hero from "../assets/c31a51da7dd939423c2eedbf7e726765.jpg";

import { Component } from "react";
import { Icon } from "@material-ui/core";
import { icons } from "react-icons";
import YouTubePlayer from "react-player/youtube";
import { Heroimg } from "../../pages/SportsConsultingDepartmentsstyles";
export class Hero extends Component {
  state = {
    a: true,
  };
  a = (e) => {
    e.preventDefault();
    console.log("ssss");
    if (this.state.a) this.setState({ a: false });
    if (!this.state.a) this.setState({ a: true });
  };
  render() {
    {
      console.log(this.state.s);
    }
    {
      console.log(this.state.a);
    }
    return (
      <HeroSection>
        <Heroimg src={hero} />
        <HeroVideo
          src="./Ipsum sports Barcelona(1080P_HD).mp4"
          muted={this.state.a}
          loop
          autoPlay
          playsInline={true}
        ></HeroVideo>
        <Container>
          <ButtonWrapper>
            <Iconnn onClick={this.a} src={icon}></Iconnn>
          </ButtonWrapper>
        </Container>{" "}
      </HeroSection>
    );
  }
}

export default Hero;
