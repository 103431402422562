import React from "react";
import { Head, Heroimg, Pherosection } from "./Media";
import hero from "../components/assets/image (1).jpg";
import hero2 from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import { Row, Space } from "./SportsConsultingDepartmentsstyles";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Box = styled.div`
  height: 150px;
  width: 250px;
  border-radius: 5px;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
`;
const Name = styled.h1`
  font-size: 19px;
  color: white;
`;
export const Button = styled.button`
  border-radius: 4px;
  background: none;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  outline: none;
  border: 2px solid #fff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 600px) {
    width: 100px;
    font-size: 12px;
  }

  &:before {
    background: #fff;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 100%;
    height: 0%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover:before {
    height: 500%;
  }

  &:hover {
    color: black;
  }
`;
function Results() {
  let history = useHistory();
  const opentab = (url) => {
    history.push(url); // Here you have to pass the route where you want to redirect
  };
  return (
    <div>
      <Pherosection>
        <Heroimg src={hero}></Heroimg>
        <Head>2023 Results</Head>
      </Pherosection>
      <Heroimg src={hero2}></Heroimg>
      <Row>
        <Box>
          <Name>FEMENINO F-7</Name>
          <Button
            onClick={() => {
              opentab("/FEMENINOF-7");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>FEMENINO F-11</Name>
          <Button
            onClick={() => {
              opentab("/FEMENINOF-11");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>U8 - PREBENJAMÍN</Name>
          <Button
            onClick={() => {
              opentab("/U8-PREBENJAMÍN");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>U9 - BENJAMÍN</Name>
          <Button
            onClick={() => {
              opentab("/U9-BENJAMÍN");
            }}
          >
            View
          </Button>
        </Box>{" "}
      </Row>{" "}
      <Space></Space>{" "}
      <Row>
        <Box>
          <Name>U10 - BENJAMÍN</Name>
          <Button
            onClick={() => {
              opentab("/U10 - BENJAMÍN");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>U11 - ALEVÍN</Name>
          <Button
            onClick={() => {
              opentab("/U11-ALEVÍN");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>U12 - ALEVÍN</Name>
          <Button
            onClick={() => {
              opentab("/U12-ALEVÍN");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>U13 - INFANTIL</Name>
          <Button
            onClick={() => {
              opentab("/U13-INFANTIL");
            }}
          >
            View
          </Button>
        </Box>{" "}
      </Row>{" "}
      <Space></Space>{" "}
      <Row>
        <Box>
          <Name>U14 - INFANTIL</Name>
          <Button
            onClick={() => {
              opentab("/U14-INFANTIL");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>U16 - CADETE</Name>
          <Button
            onClick={() => {
              opentab("/U16-CADETE");
            }}
          >
            View
          </Button>
        </Box>{" "}
        <Box>
          <Name>U19 -JUVENIL</Name>
          <Button
            onClick={() => {
              opentab("/U19-JUVENIL");
            }}
          >
            View
          </Button>
        </Box>{" "}
      </Row>
    </div>
  );
}

export default Results;
