import React from "react";
import { useState, useEffect } from "react";
import { Heads, Iconn, Name } from "../Servicos/Servciosstyles";
import icon from "../assets/icons8-services-64.png";
import hero from "../assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import { useHistory } from "react-router-dom";

import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import {
  Column,
  Servicesbox,
  ServicesHead,
  Servicesimg,
  Servicess,
  Servicestexts,
  Servicestextx,
  Spacemain,
} from "./Servicesstyles";
import img1 from "../assets/edu_DSC1591-1.png";
import img2 from "../assets/IMG_6336(1).jpg";
import img3 from "../assets/Foto 6.jpg";
import { Button, Container, Heading, TextWrapper } from "../../globalStyles";
import { Blur, ContentButton, ContentColumn } from "../Content/ContentStyles";
import { Heroimg, Space } from "../../pages/SportsConsultingDepartmentsstyles";
function Services() {
  var serA = [
    "استعرض خدماتنا",
    "أقسام الاستشارات الرياضية",
    "العلاج و الشفاء",
    "معسكر نخبوي فردي",
  ];
  var serE = [
    "Explore Our Services",
    "Sports Consulting Departments",
    "Treatment Recovery",
    "Elite Individual",
  ];
  var desA = [
    "نعمل على دراسة وتقديم الخطة المناسبة والفعالة للأندية والمنتخبات والأكاديميات.",
    "  التقييم العصبي العضلي والبنيوي والفيزيولوجي، وإعداد تقارير مفصلة عن الوضع الحالي البدني والرياضي.",
    "إجراء الاختبارات والتقييم الهيكلي والفيزيولوجي والعصبي العضلي الشامل والحالة البدنية للّاعب.",
    "المزيد",
  ];
  var desE = [
    "We design an appropriate and effective plan for clubs and teams and academies.",

    "We examine the neuromuscular, structural, and physiological health of our players . ",

    "Comprehensive structural, physiological, and neuromuscular examinations .",
    "Find More",
  ];
  let history = useHistory();

  const [sers, setSers] = useState(serE);
  const [descs, setDescs] = useState(desE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const opentab = (url) => {
    history.push(url); // Here you have to pass the route where you want to redirect
  };
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setSers(serA);
      setDescs(desA);
    } else {
    }
  };
  const initial = { opacity: 0, y: 50 };
  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    checklng();
  }, []);
  useEffect(() => {
    if (inView) {
      console.log("sss");
      animation.start({
        opacity: 1,
        y: 1,
      });
    }
  }, [inView, animation]);
  return (
    <>
      <Servicess ref={ref}>
        {" "}
        <Spacemain />
        <Heroimg src={hero} />
        <ServicesHead>
          <Iconn src={icon}></Iconn> {sers[0]}
        </ServicesHead>
        <Column>
          {" "}
          <Heroimg src={hero} />
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/Sports-Consulting-Departments");
              }}
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              whileHover={{ rotate: 2, scale: 1.02 }}
              src={img1}
            ></Servicesimg>
            <Space />
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[1]}
                <Servicestexts>{descs[0]}</Servicestexts>
              </Servicestextx>{" "}
            </motion.div>
          </Servicesbox>
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/Treatment-Recovery");
              }}
              whileHover={{ rotate: 2, scale: 1.02 }}
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              src={img2}
            ></Servicesimg>{" "}
            <Space />{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[2]}
                <Servicestexts>{descs[1]}</Servicestexts>
              </Servicestextx>
            </motion.div>
          </Servicesbox>
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/Elite-Individual");
              }}
              whileHover={{ rotate: 2, scale: 1.02 }}
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              src={img3}
            ></Servicesimg>{" "}
            <Space />{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[3]}
                <Servicestexts>{descs[2]}</Servicestexts>
              </Servicestextx>
            </motion.div>
          </Servicesbox>
        </Column>{" "}
        <Blur></Blur>
      </Servicess>
    </>
  );
}

export default Services;
