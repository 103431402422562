import React, { useEffect, useState } from "react";
import {
  Backgroundimg,
  Head,
  Image1,
  Image2,
  Image3,
  Lefttop,
  Mvideo,
  Pherosection,
  Rightbottom,
  Space,
  Row,
  Underline,
  Image4,
  Image44,
  Image33,
  Image11,
  Heroimg,
  Space1,
  Divider,
  Lefttop2,
} from "./SportsConsultingDepartmentsstyles";
import hero from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";

import img1 from "../components/assets/IMG-20221126-WA0019.jpg";
import img2 from "../components/assets/IMG-20221126-WA0018.jpg";
import img3 from "../components/assets/IMG-20221126-WA0020.jpg";
import img4 from "../components/assets/Foto 1.jpg";
import img5 from "../components/assets/foto 7.jpg";
import img6 from "../components/assets/Foto 8.jpg";
import img7 from "../components/assets/Foto 3.jpg";
import img8 from "../components/assets/IMG-20221211-WA0039.jpg";
import img9 from "../components/assets/IMG-20221211-WA0039.jpg";
import img10 from "../components/assets/file2-1.jpeg";
import img11 from "../components/assets/IMG-20221211-WA0041.jpg";
import img12 from "../components/assets/IMG-20221218-WA0001.jpg";
import img13 from "../components/assets/IMG-20221231-WA0015.jpg";
import img14 from "../components/assets/IMG_6336(1).jpg";
function TreatmentRecovery() {
  var descE = [
    "  Treatment & Recovery ",
    "We evaluate and diagnose each player’s overall physical condition and injuries before working through the following stages: ",
    "Check Up ,  Active Recovery ",
    "Our medical team diagnoses each injury and works to find appropriate solutions through treatment or surgery.",
    " Physiotherapy is conducted by a team of therapists using the latest equipment and technologies from first-class Spanish clubs.",
    "Strength, Speed, and Agility",
    "Work here is toward developing neuromuscular control of basic abilities (such as changing direction and jumps) and integrating strength, work in basic skills and spatial awareness to develop speed and agility. ",
    "On-Field",
    "Individual and group training to reinforce the physical and psychological skills developed in the previous stages.",
    "Techniques used throughout the stages, as needed:",
    " physiological evaluations",
    " neuromuscular evaluations",
    " morpho-structural evaluations",
    "load control",
    "deep diathermy",
    " ultrasound",
    "percutaneous neuromodulation therapy",
    " percutaneous intratissue electrolysis",
    "normatec",
    "myofascial relaxation",
  ];
  var descA = [
    "العلاج و الشفاء",
    "نقوم بتقييم وتشخيص حالة اللاعب البدنية بشكل عام والإصابة بشكل خاص للبدء بمراحل العمل",
    "الفحص و الانتعاش النشط",
    "تشخيص الإصابة من قبل فريقنا الطبي والعمل على إيجاد الحلول المناسبة عن طريق العلاج أو العمل الجراحي ",
    " العلاج الطبيعي والفيزيائي من قبل فريق المعالجين المجهز بأحدث الأجهزة والتقنيات المستخدمة في أندية الدرجة الأولى الإسبانية ",
    "القوة والسرعة وخفة الحركة",
    "خلال  هذه المرحلة نقوم بتطوير تقنية التحكم العصبي والعضلي للقدرات الأساسية كتغير الاتجاه والقفزات، ونقوم أيضاً بدمج أعمال القوة القصوى في المهارات الأساسية والتوجه إلى الملعب لتطوير السرعة وخفة الحركة",
    "على ارضية الملعب",
    "وهي المرحلة النهائية ضمن الملعب والتي تشمل التدريب الفردي والجماعي لاستعادة الحالة البدنية والنفسية والمهارات التكنيكية السابقة وتطويرها",
    "تعتمد مراحل العمل على التقنيات التالية حسب حاجة اللاعبين",
    " physiological evaluations",
    " neuromuscular evaluations",
    " morpho-structural evaluations",
    "load control",
    "deep diathermy",
    " ultrasound",
    "percutaneous neuromodulation therapy",
    " percutaneous intratissue electrolysis",
    "normatec",
    "myofascial relaxation",
  ];
  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);

  return (
    <div>
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Mvideo
          src="https://www.youtube.com/embed/VsSbrGS5uVg"
          title="Treatment And Recovery"
          frameborder="0"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      </Pherosection>
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[0]}</Head>
          <Underline />
          {data[1]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Image11 src={img1} /> <Space />
              <Image11 src={img2} />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} /> <Space />
        <Rightbottom>
          <Row>
            <Row>
              <Image33 src={img3} />
              <Space />
            </Row>{" "}
            <Space />
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Space /> <Image33 src={img4} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image33 src={img5} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[2]}</Head>
          <Underline />
          {data[3]}
          <br />
          <br />
          {data[4]}
        </Lefttop>
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[5]}</Head>
          <Underline />
          {data[6]}
        </Lefttop>{" "}
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image33 src={img6} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image33 src={img7} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image33 src={img8} />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} /> <Space />
        <Rightbottom>
          <Row>
            <Row>
              <Space />
              <Image33 src={img9} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image33 src={img10} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image33 src={img11} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[7]}</Head>
          <Underline />
          {data[8]}
        </Lefttop>
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop2>
          <Head> {data[9]}</Head>
          <Underline />
          {data[10]}
          <br />
          {data[11]}
          <br />
          {data[12]}
          <br />
          {data[13]}
          <br />
          {data[14]}
          <br />
          {data[15]}
          <br />
          {data[16]}
          <br />
          {data[17]}
          <br />
          {data[18]}
          <br />
          {data[19]}
          <br />
          {data[20]}
          <br />
        </Lefttop2>{" "}
        <Rightbottom>
          <Row>
            <Row>
              <Space />
              <Image33 src={img12} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image33 src={img13} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              {" "}
              <Image33 src={img14} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
    </div>
  );
}

export default TreatmentRecovery;
