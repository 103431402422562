import React, { useEffect, useState } from "react";
import hero from "../assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import {
  Button,
  Head,
  Heroimg,
  Image2,
  Pherosection,
  Regimg,
  Rightbottom,
  Row,
  Underline,
} from "./SportsConsultingDepartmentsstyles";
import { Contactrow } from "./Contactstyles";
import Ac from "../components/assets/IMG-20221213-WA0029.jpg";

function Regst() {
  const descA = [
    "تسجيل على معسكر الأكاديمية",
    "مدة العسكر ١٥ يوم في مدينة برشلونة من 1/8/2023 إلى 15/8/2023 ",
    "  نتكفل بكل ما يتعلق بالمعسكر التدريبي منذ لحظة الوصول و حتى المغادرة  ",
    "التدريب الصباحي و المسائي بالإضافة لخوض العديد من المباريات الودية و التجريبية ",
    "الوجبات الغذائية الكاملة ( الفطور ـ الغداء ـ العشاء )  ",
    "وسائل النقل الخاصة و الآمنة لتغطية جميع التنقلات   ",
    "الأنشطة الترفيهية و الرياضية و السياحية و التعليمية   ",
    "الحصول على شهادة و فيديو شخصي و صور اللاعب لكامل تجربته في المعسكر ",
    "حصول اللاعب على القميص الرسمي لنادي برشلونة موقع من اللاعبين المتواجدين",
  ];
  const descE = [
    "Registration For Academy Camp",
    "-The duration of the Camp is 15 days in Barcelona City from 1/8/2023 to 15/8/2023",
    "-We take care of everything related to the training camp from the moment of arrival until departure",
    "-Morning and evening training, playing many friendly and experimental matches  ",
    "-5 Stars Accommodation included 3 meals (breakfast, lunch, dinner)",
    "-Private and Luxury transportation ",
    "-Entertainment, sports, education, and tourism activities.",
    "-Obtaining a certificate, a personal video, and pictures of the player for his entire experience in the camp.",
    "-The player obtains the official FC Barcelona shirt, signed by the players present.  ",
  ];

  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);
  function Payment() {
    window.open("https://es.social-commerce.io/hGdAiS");
  }
  return (
    <Pherosection>
      <Heroimg src={hero} />
      <Regimg src={Ac}></Regimg>
      <br></br>
      <Rightbottom>
        <Head> {data[0]}</Head> <br></br>
        <Underline /> <br></br>
        {data[1]}
        <br></br>
        {data[2]}
        <br></br>
        {data[3]}
        <br></br>
        {data[4]}
        <br></br>
        {data[5]}
        <br></br>
        {data[6]}
        <br></br>
        {data[7]}
        <br></br>
        {data[8]}
        <br></br> <br></br>
        <Button onClick={Payment}>Payment</Button>
      </Rightbottom>
    </Pherosection>
  );
}

export default Regst;
