import React from "react";
import { useState, useEffect } from "react";
import { Blur } from "../Content/ContentStyles";
import { Heads, Iconn, Name } from "../Servicos/Servciosstyles";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import {
  Column,
  Servicesbox,
  ServicesHead,
  ServicesHeadCamp,
  Servicesimg,
  Servicess,
  Servicestexts,
  Servicestextx,
  Spacemain,
} from "../Services/Servicesstyles";
import img1 from "../assets/IMG-20221211-WA0068.jpg";
import img2 from "../assets/IMG-20221213-WA0028.jpg";
import img3 from "../assets/IMG-20221211-WA0040.jpg";
import icon from "../assets/icons8-camp-66.png";
import hero from "../assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import { Heroimg, Space } from "../../pages/SportsConsultingDepartmentsstyles";
function Camp() {
  var serA = [
    "استعرض المعسكرات",
    " معسكرات متميزة",
    "معسكر الأكاديمية",
    "معسكر تدريب خارج الموسم",
  ];
  var serE = [
    "Explore Our Camps",
    "Premier Camps",
    "Academy Camp",
    "Training Camp(Off Season)",
  ];
  var desA = [
    "توفير المعسكرات الخارجية للأندية والمنتخبات الوطنية لجميع الفئات العمرية وتأمين كافة الخدمات المتعلقة بالمعسكر .",
    "معسكر وتجمع نخبوي بحضور وتواجد عدد من لاعبي نادي برشلونة والمنتخب الإسباني، ",
    "هذه الخدمة تشمل خدمة (معسكر نخبوي فردي) ضمن معسكر مع نخبة من لاعبي أندية الدرجة الأولى الإسبانية والأوروبية",
  ];
  var desE = [
    "Gain access to external camps for elite clubs and national teams and enjoy all camp-related services   ",

    "Experience an elite camp with players from FC Barcelona and the Spanish national team. ",

    "Join elite players at a football camp for first-class Spanish and European clubs.",
  ];
  const [sers, setSers] = useState(serE);
  const [descs, setDescs] = useState(desE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setSers(serA);
      setDescs(desA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);
  let history = useHistory();
  const initial = { opacity: 0, y: 50 };
  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.2 });
  useEffect(() => {
    if (inView) {
      console.log("sss");
      animation.start({
        opacity: 1,
        y: 1,
      });
    }
  }, [inView, animation]);
  const opentab = (url) => {
    history.push(url); // Here you have to pass the route where you want to redirect
  };
  return (
    <>
      <Servicess ref={ref}>
        <Spacemain /> <Heroimg src={hero} />
        <ServicesHeadCamp>
          <Iconn src={icon}></Iconn> {sers[0]}
        </ServicesHeadCamp>
        <Column>
          <Heroimg src={hero} />
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/Premier-Camps");
              }}
              whileHover={{ rotate: 2, scale: 1.02 }}
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              src={img1}
            ></Servicesimg>{" "}
            <Space />{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[1]}
                <Servicestexts>{descs[0]}</Servicestexts>
              </Servicestextx>
            </motion.div>
          </Servicesbox>
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/Academy-Camp");
              }}
              whileHover={{ rotate: 2, scale: 1.02 }}
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              src={img2}
            ></Servicesimg>{" "}
            <Space />{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[2]}
                <Servicestexts>{descs[1]}</Servicestexts>
              </Servicestextx>
            </motion.div>
          </Servicesbox>
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/Training-Camp");
              }}
              whileHover={{ rotate: 2, scale: 1.02 }}
              t
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              src={img3}
            ></Servicesimg>{" "}
            <Space />{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[3]}
                <Servicestexts>{descs[2]}</Servicestexts>
              </Servicestextx>
            </motion.div>
          </Servicesbox>
        </Column>{" "}
        <Blur></Blur>
      </Servicess>
    </>
  );
}

export default Camp;
