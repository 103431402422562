import React from "react";
import GlobalStyle, { Animate, Mobileimg } from "./globalStyles";
import { useState, useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import AcademyCamp from "./pages/AcademyCamp";
import TrainingCamp from "./pages/TrainingCamp";
import PremierCamps from "./pages/PremierCamps";
import EliteIndividual from "./pages/EliteIndividual";
import SportsConsultingDepartments from "./pages/SportsConsultingDepartments";
import TreatmentRecovery from "./pages/TreatmentRecovery";
import DeviceOrientation, { Orientation } from "react-screen-orientation";

import Navbar from "./components/Navbar/Navbar";
import Camp from "./pages/AcademyCamp";
import { isMobile, isTablet, isIPad13, isDesktop } from "react-device-detect";
import img from "./components/assets/icons8-rotate-screen-90.png";
import Contact from "./pages/Contact";
import Regst from "./pages/Regst";
import Results from "./pages/Results";
import Tournamnet2024 from "./pages/Tournamnet2024";
import Media from "./pages/Media";
function App() {
  const [Mobile, setMobile] = useState(true);
  const [Tablet, setTablet] = useState(true);
  const [Ipad, setIpad] = useState(true);
  const Checkdevice = () => {
    if (isTablet == true) {
      console.log("tablet");

      setTablet(false);
      setMobile(false);
    } else if (isIPad13 == true) {
      console.log("ipad");
      setIpad(false);
    } else if (isMobile == true) {
      console.log("mobile");

      setMobile(false);
    }
  };
  useEffect(() => {
    Checkdevice();
  }, []);
  if (isTablet == true || isDesktop == true)
    return (
      <DeviceOrientation lockOrientation={"portrait"}>
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="portrait" alwaysRender={true}>
          <Router>
            <GlobalStyle />
            <Navbar />
            <switch>
              <Route path="/" exact component={Home} />

              <Route path="/Academy-Camp" component={AcademyCamp} />
              <Route path="/Training-Camp" component={TrainingCamp} />
              <Route path="/Elite-Individual" component={EliteIndividual} />
              <Route path="/Premier-Camps" component={PremierCamps} />
              <Route path="/Contact" component={Contact} />
              <Route path="/TORNAMENT2024" component={Tournamnet2024} />
              <Route path="/MEDIA" component={Media} />
              <Route path="/results" component={Results} />
              <Route
                path="/Sports-Consulting-Departments"
                component={SportsConsultingDepartments}
              />
              <Route path="/Treatment-Recovery" component={TreatmentRecovery} />
            </switch>
            <Footer />
          </Router>
        </Orientation>
      </DeviceOrientation>
    );
  else if (isMobile == true)
    return (
      <DeviceOrientation lockOrientation={"portrait"}>
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <Router>
            <GlobalStyle />
            <Navbar />
            <switch>
              <Route path="/" exact component={Home} />

              <Route path="/Academy-Camp" component={AcademyCamp} />
              <Route path="/Training-Camp" component={TrainingCamp} />
              <Route path="/Elite-Individual" component={EliteIndividual} />
              <Route path="/Premier-Camps" component={PremierCamps} />
              <Route path="/Contact" component={Contact} />
              <Route path="/TORNAMENT2024" component={Tournamnet2024} />
              <Route path="/MEDIA" component={Media} />
              <Route path="/results" component={Results} />
              <Route
                path="/Sports-Consulting-Departments"
                component={SportsConsultingDepartments}
              />
              <Route path="/Treatment-Recovery" component={TreatmentRecovery} />
            </switch>
            <Footer />
          </Router>
        </Orientation>
        <Orientation orientation="landscape" alwaysRender={false}>
          <Animate>
            <Mobileimg src={img} />
          </Animate>
        </Orientation>
      </DeviceOrientation>
    );
}

export default App;
