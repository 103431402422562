import styled from "styled-components";

export const Servcio = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 5rem;
  @media screen and (max-width: 970px) {
    flex-direction: column;
    height: 100%;
    gap: 0rem;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-evenly;
  gap: 1rem;
  padding: 0rem 1rem 3rem 1rem;

  @media screen and (max-width: 970px) {
    flex-direction: column;
    height: 100%;
    padding: 0rem 0rem 0rem 0rem;
  }
`;
export const Heads = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  text-align: center;
  z-index: 1;

  font-size: 50px;
  color: white;
  font-family: "Signika", sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const Item = styled.div`
  padding-bottom: 2rem;
  height: 40vh;
  justify-content: center;
  text-align: center;
  width: 33%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  @media screen and (max-width: 970px) {
    width: 100%;
    height: 100%;
  }
`;

export const Name = styled.h2`
  color: white;
  padding: 1rem 0rem 0rem 0rem;
`;
export const Foto = styled.img`
  width: 80%;
  height: 300px;
  border-radius: 0px;
  /* border-top: BLACK solid 5px;
border-left: BLACK solid 5px;
  border-right: BLACK solid 5px;
  border-bottom: BLACK solid 5px;  */
  object-fit: fill;
  @media screen and (max-width: 970px) {
    width: 70%;
    height: 250px;
  }
`;
export const Iconn = styled.img`
  text-align: center;
  margin-right: 10px;
  height: 40px;
  width: 40px;
`;
export const Iconnn = styled.img`
  padding-right: 5px;

  height: 25px;
  width: 30px;
`;
