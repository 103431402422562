import React, { useEffect, useState } from "react";
import hero from "../components/assets/image.jpg";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useRef } from "react";
import styled, { keyframes } from "styled-components";
import iimg from "../components/assets/icons8-image-50.png";
import ivid from "../components/assets/icons8-video-50.png";
import img1 from "../components/assets/photo_2024-01-05_00-32-28.jpg";
import img2 from "../components/assets/photo_2024-01-05_00-32-33.jpg";
import img3 from "../components/assets/photo_2024-01-05_00-32-15.jpg";
import img4 from "../components/assets/photo_2024-01-05_00-32-24.jpg";
import img5 from "../components/assets/photo_2024-01-05_00-32-16.jpg";
import img6 from "../components/assets/photo_2024-01-05_00-32-17.jpg";
import img7 from "../components/assets/photo_2024-01-05_00-32-20.jpg";
import img8 from "../components/assets/photo_2024-01-05_00-32-21.jpg";
import img9 from "../components/assets/photo_2024-01-05_00-32-22.jpg";
import img10 from "../components/assets/photo_2024-01-05_00-32-23.jpg";
import img11 from "../components/assets/photo_2024-01-05_00-32-27.jpg";
import img12 from "../components/assets/photo_2024-01-05_00-32-25.jpg";
import img13 from "../components/assets/photo_2024-01-05_00-32-26.jpg";
import img14 from "../components/assets/FB_IMG_1702584675930.jpg";
import img15 from "../components/assets/FB_IMG_1702584699584.jpg";
import img16 from "../components/assets/photo_2024-01-05_00-32-33.jpg";
import img17 from "../components/assets/FB_IMG_1702584713601.jpg";
import img18 from "../components/assets/FB_IMG_1702585592563.jpg";
import img19 from "../components/assets/FB_IMG_1704400187846.jpg";
import img20 from "../components/assets/FB_IMG_1704400196888.jpg";
import img21 from "../components/assets/FB_IMG_1704400384929.jpg";
import img22 from "../components/assets/photo_2024-01-05_00-32-31.jpg";
import hero2 from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import { Iconn } from "../components/Servicos/Servciosstyles";
import { motion } from "framer-motion";

const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
];
const videos = [
  "https://www.youtube.com/embed/4o4_znPh7fw",
  "https://www.youtube.com/embed/Q6n8GWSXN4c",
  "https://www.youtube.com/embed/rd-4qC_24Xo",
  "https://www.youtube.com/embed/czTATb_Mj14",
  "https://www.youtube.com/embed/AwrxHHs1Fhs",
  "https://www.youtube.com/embed/KzRon1SKQVk",
  "https://www.youtube.com/embed/RCs4DdzZC08",
  "https://www.youtube.com/embed/8ogyaFJ4OaU",
];
export const Vid = styled(motion.iframe)`
  width: 90%;
  height: 40vh;
  border-radius: 2px;
  box-shadow: -5px -5px 2px grey;
  margin-left: 20px;
  @media screen and (max-width: 970px) {
    height: 100%;
    width: 85%;
  }
`;
export const Pherosection = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  align-items: center;
  color: #979797;
  @media screen and (max-width: 970px) {
    height: 50vh;
    flex-direction: column;
  }
`;
export const Heroimg = styled.img`
  object-fit: fill;
  width: 100%;
  background-color: black;
  height: 100%;
  position: absolute;
  z-index: -1;
  @media screen and (max-width: 968px) {
    object-fit: fill;
    width: 100%;
    height: 100vh;
  }
`;
export const Head = styled.h1`
  z-index: 1;
  font-size: 60px;
  letter-spacing: 2px;

  color: white;
`;
export const Head2 = styled.h1`
  z-index: 1;
  font-size: 60px;
  letter-spacing: 2px;

  color: white;
`;
const ServicesHead = styled.div`
  color: white;
  text-align: center;
  font-size: 3rem;
  line-height: 1.1;
  z-index: 1;
  font-weight: 600;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
function Media() {
  return (
    <div>
      {" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} /> <Head>Gallrey</Head>
      </Pherosection>{" "}
      <Heroimg src={hero2} />{" "}
      <ServicesHead>
        <Iconn src={iimg}></Iconn>
        {"Images"}
      </ServicesHead>
      <Carousel images={images} />
      <Heroimg src={hero2} />{" "}
      <ServicesHead>
        <Iconn src={ivid}></Iconn>
        {"Videos"}
      </ServicesHead>
      <Carousel2 videos={videos} />
    </div>
  );
}

export default Media;
const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;

  width: 100%;
  overflow: hidden;
`;

const CarouselImageContainer = styled.div`
  width: 33.33%;
  @media screen and (max-width: 970px) {
    width: 90%;
  }
  height: auto;
`;

const CarouselImage = styled.img`
  width: 300px;
  border-radius: 2px;
  box-shadow: -5px -5px 2px grey;

  height: 250px;
  margin-left: 30px;
  @media screen and (max-width: 970px) {
    width: 150px;

    height: 200px;
    margin-left: 10px;
  }
`;

const CarouselButton = styled.button`
  background: none;
  border: none;
  size: 3rem;
  cursor: pointer;
`;
const Carousel = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const touchStartRef = useRef(null);

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTouchStart = (e) => {
    touchStartRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchStart = touchStartRef.current;

    if (touchStart - touchEnd > 100) {
      handleNext();
    } else if (touchEnd - touchStart > 100) {
      handlePrev();
    }
  };

  const isMobile = window.innerWidth <= 768;
  const numVisibleImages = isMobile ? 2 : 3;

  return (
    <CarouselContainer
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <CarouselButton onClick={handlePrev}>
        <IconContext.Provider
          value={{ size: isMobile ? "1.2rem" : "3rem", color: "#979797" }}
        >
          <FaArrowCircleLeft />
        </IconContext.Provider>
      </CarouselButton>

      {images
        .slice(activeIndex, activeIndex + numVisibleImages)
        .map((image, index) => (
          <CarouselImageContainer key={index}>
            <CarouselImage src={image} alt={`Image ${index + 1}`} />
          </CarouselImageContainer>
        ))}

      <CarouselButton onClick={handleNext}>
        <IconContext.Provider
          value={{ size: isMobile ? "1.2rem" : "3rem", color: "#979797" }}
        >
          <FaArrowCircleRight />
        </IconContext.Provider>
      </CarouselButton>
    </CarouselContainer>
  );
};
const Carousel2 = ({ videos }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const touchStartRef = useRef(null);

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? videos.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTouchStart = (e) => {
    touchStartRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchStart = touchStartRef.current;

    if (touchStart - touchEnd > 100) {
      handleNext();
    } else if (touchEnd - touchStart > 100) {
      handlePrev();
    }
  };

  const isMobile = window.innerWidth <= 768;
  const numVisiblevideos = isMobile ? 2 : 3;

  return (
    <CarouselContainer
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <CarouselButton onClick={handlePrev}>
        <IconContext.Provider
          value={{ size: isMobile ? "1.2rem" : "3rem", color: "#979797" }}
        >
          <FaArrowCircleLeft />
        </IconContext.Provider>
      </CarouselButton>

      {videos
        .slice(activeIndex, activeIndex + numVisiblevideos)
        .map((video, index) => (
          <CarouselImageContainer key={index}>
            <Vid
              frameborder="0"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              src={video}
              alt={`video ${index + 1}`}
            />
          </CarouselImageContainer>
        ))}

      <CarouselButton onClick={handleNext}>
        <IconContext.Provider
          value={{ size: isMobile ? "1.2rem" : "3rem", color: "#979797" }}
        >
          <FaArrowCircleRight />
        </IconContext.Provider>
      </CarouselButton>
    </CarouselContainer>
  );
};
