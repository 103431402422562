import styled from "styled-components";
import { motion, useMotionTemplate } from "framer-motion";

export const Servicess = styled.div`
  width: 100%;

  display: flex;
  flex-basis: 100%;

  flex-direction: column;
  @media screen and (max-width: 960px) {
    height: 150vh;
  }
`;
export const ServicesHead = styled.div`
  color: white;
  text-align: center;
  font-size: 3rem;
  line-height: 1.1;
  z-index: 1;
  font-weight: 600;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin: -130px 0px 280px;
  }
`;
export const ServicesHeadCamp = styled.div`
  color: white;
  text-align: center;
  font-size: 3rem;
  line-height: 1.1;
  z-index: 1;
  font-weight: 600;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin: -0px 0px 350px;
  }
`;
export const ServicesHeadMedia = styled.div`
  color: white;
  text-align: center;
  font-size: 3rem;
  line-height: 1.1;
  z-index: 1;
  font-weight: 600;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin: 550px 0px 200px;
  }
`;
export const Servicesbox = styled.div`
  height: 55vh;
  margin-top: 50px;
  width: 25%;

  display: flex;
  flex-direction: column;

  opacity: 1;
  justify-content: space-evenly;
  @media screen and (max-width: 960px) {
    justify-content: space-evenly;

    height: 50vh;
    width: 80%;
  }
`;
export const Servicesimg = styled(motion.img)`
  object-fit: fill;
  width: 100%;
  height: 40vh;
  opacity: 0.85;
  border-radius: 2px;  box-shadow:-10px -10px  2px grey;
  cursor: pointer;

  @media screen and (max-width: 960px) {
    box-shadow:-8px -8px  2px grey;
    height: 25vh;
    width: 80vw;

`;
export const Servicestextx = styled.div`
  border-radius: 2px;
  box-shadow: 10px 10px 2px grey;

  height: 12vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-top: 10px;
  justify-content: center;
  color: black;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  background-color: white;
  opacity: 1;
  @media screen and (max-width: 960px) {
    box-shadow: 8px 8px 2px grey;
    font-size: 15px;

    height: 12vh;
  }
`;
export const Column = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media screen and (max-width: 960px) {
    align-items: center;
    text-align: center;

    flex-direction: column;
  }
`;

export const Servicestexts = styled.div`
  font-size: 10px;
  padding: 0px;
  margin: 15px;
  margin-top: 10px;
  font-weight: 200;

  color: #979797;
`;
export const Spacemain = styled.div`
  @media screen and (max-width: 960px) {
    height: 100px;
  }
`;
