import React, { useEffect, useState } from "react";
import { Heads, Iconn, Name } from "../Servicos/Servciosstyles";
import { Continer, Main, Main2, Spacemedia, Vid } from "./Mediastyles";
import media from "../assets/icons8-youtube-100.png";
import {
  BSpace,
  Heroimg,
  Space,
} from "../../pages/SportsConsultingDepartmentsstyles";
import hero from "../assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import {
  Column,
  ServicesHead,
  ServicesHeadMedia,
  Spacemain,
} from "../Services/Servicesstyles";
import { useHistory } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";

const Media = () => {
  var descE = ["        WATCH OUR MEDIA  "];
  var descA = ["        شاهد فيديوهات  "];
  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);

  let history = useHistory();
  const initial = { opacity: 0, y: 50 };
  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.2 });
  useEffect(() => {
    if (inView) {
      console.log("sss");
      animation.start({
        opacity: 1,
        y: 1,
      });
    }
  }, [inView, animation]);
  return (
    <div>
      <Main ref={ref}>
        {" "}
        <Spacemain />
        <Heroimg src={hero} />
        <ServicesHeadMedia>
          <Iconn src={media} />
          {data[0]}
        </ServicesHeadMedia>
        <Column>
          <Continer>
            <motion.div
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
            >
              <Vid
                src="https://www.youtube.com/embed/lOIjtrHIFcw"
                title="Off Season 2022 (Nico González)"
                frameborder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></Vid>{" "}
            </motion.div>{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Name>Nico González</Name>
            </motion.div>
            <Spacemedia />
          </Continer>
          <Space />
          <Continer>
            {" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
            >
              <Vid
                src="https://www.youtube.com/embed/Vvfjh9O4BMU"
                title="Off Season 2022 (Nico Melamed)"
                frameborder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></Vid>{" "}
            </motion.div>{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Name>Nico Melamed</Name>
            </motion.div>
            <Spacemedia />
          </Continer>{" "}
          <Space />{" "}
          <Continer>
            {" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
            >
              <Vid
                src="https://www.youtube.com/embed/4sRCa0nVxZE"
                title="Off Season 2022 (Eric García)"
                frameborder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></Vid>
            </motion.div>
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Name>Eric García</Name>
            </motion.div>
            <Spacemedia />
          </Continer>
          <Space />{" "}
          <Continer>
            {" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
            >
              <Vid
                src="https://www.youtube.com/embed/KpoxKFk1MiE"
                title="Off Season 2022 (Eric García)"
                frameborder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></Vid>
            </motion.div>
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Name>Alex Collado</Name>
            </motion.div>
            <Spacemedia />
          </Continer>
        </Column>
      </Main>{" "}
      <BSpace /> <BSpace />
      <BSpace /> <BSpace />
      <BSpace /> <BSpace />
    </div>
  );
};

export default Media;
