import React, { useEffect, useState } from "react";
import {
  Backgroundimg,
  Head,
  Image1,
  Image2,
  Image3,
  Lefttop,
  Mvideo,
  Pherosection,
  Rightbottom,
  Space,
  Row,
  Underline,
  Image4,
  Image44,
  Image33,
  BSpace,
  Heroimg,
  Image22,
  Divider,
} from "./SportsConsultingDepartmentsstyles";
import hero from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";

import img1 from "../components/assets/IMG-20221211-WA0037.jpg";
import img2 from "../components/assets/IMG-20221211-WA0030.jpg";
import img3 from "../components/assets/training3-15z36x885.jpg";

function TrainingCamp() {
  var descA = [
    "معسكر تدريب خارج الموسم",
    "من أهم الخدمات التي تقدمها الشركة للاعبين وأكثرها تميزاً، منحهم الفرصة لخوض هذه التجربة المميزة والفريدة من نوعها، والتعايش مع نخبة من نجوم ولاعبي الأندية الإسبانية والأوربية من خلال معسكر تدريبي خاص ومميز",
    "مثال",
    "جدول المهام يومية",
  ];
  var descE = [
    "Training Camp(Off Season)",
    "Our most distinguished service is the opportunity for a unique experience at a special training camp with a group of stars and players from Spanish and European clubs.",
    "Example",
    "Daily task schedule",
  ];
  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);
  return (
    <div>
      {" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Mvideo
          src="https://www.youtube.com/embed/QvjuOwLcjFM"
          title="Training Camp (OffSeason]"
          frameborder="0"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      </Pherosection>
      <Divider />
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[0]}</Head>
          <Underline />
          {data[1]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img1} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image2 src={img2} />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[2]}</Head>
          <Underline />
          {data[3]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image22 src={img3} />
            </Row>
          </Row>{" "}
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <BSpace /> <BSpace /> <BSpace />
    </div>
  );
}

export default TrainingCamp;
