import styled from "styled-components";
export const Pherosection = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  align-items: center;
  color: #979797;
  @media screen and (max-width: 970px) {
    height: 100%;
    flex-direction: column;
  }
`;
export const Heroimg = styled.img`
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(180deg);
  z-index: -1;
  @media screen and (max-width: 968px) {
    object-fit: fill;
    width: 100%;
    height: 158vh;
  }
`;

export const Mvideo = styled.iframe`
  margin-top: 50px;
  height: 80%;
  z-index: 1;
  width: 85%;
  @media screen and (max-width: 968px) {
    height: 40vh;
  }
`;
export const Lefttop = styled.div`
  height: 50vh;
  width: 45%;
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  font-size: 20px;

  align-items: center;
  @media screen and (max-width: 970px) {
    margin-top: 15%;
    height: 50vh;
    font-size: 14px;

    width: 95%;
  }
`;
export const Lefttop2 = styled.div`
  height: 55vh;
  width: 45%;
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  font-size: 17px;

  align-items: center;
  @media screen and (max-width: 970px) {
    margin-top: 15%;
    height: 50vh;
    font-size: 14px;

    width: 95%;
  }
`;
export const Rightbottom = styled.div`
  width: 50%;
  display: flex;
  z-index: 1;

  flex-direction: column;

  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  @media screen and (max-width: 970px) {
    font-size: 13px;
    width: 100%;
  }
`;
export const Button = styled.div`
  border-radius: 2px;
  background: none;
  width: 160px;
  display: flex;
  flex-direcation: row;
  justify-content: space-evenly;
  align-items: center;
  height: 45px;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 12px")};
  font-weight: 1000;
  color: #fff;
  font-size: ${({ fontbig }) => (fontbig ? "20px" : "14px")};
  border: 2px solid white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-family: "Mulish";

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 102%;
    height: 0%;
  }
  &:hover:before {
    height: 200%;
  }
  &:hover {
    z-index: 1;
    background: white;

    color: black;
  }
  @media screen and (max-width: 968px) {
    margin-top: 40px;
    width: 135px;
    font-size: small;
  }
`;
export const Underline = styled.div`
  height: 2px;
  width: 40%;
  background-color: white;
  margin-top: 10px;
  z-index: 1;
  postion: fixed;
  display: flex;
  margin-bottom: 10px;
`;
export const Head = styled.div`
  font-size: 35px;
  font-weight: 600;
  color: white;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media screen and (max-width: 970px) {
  }
`;
export const Image2 = styled.img`
  height: 35vh;
  width: 35vw;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 2.5px grey;

  @media screen and (max-width: 970px) {
    height: 28vh;

    width: 80vw;
  }
`;
export const Image22 = styled.img`
  height: 45vh;
  width: 40vw;
  box-shadow: 0px 0px 5px 2.5px grey;

  border-radius: 2px;
  @media screen and (max-width: 970px) {
    height: 28vh;

    width: 80vw;
  }
`;

export const Image1 = styled.img`
  height: 65vh;
  width: 30vw;
  box-shadow: 0px 0px 5px 2.5px grey;

  border-radius: 2px;
  @media screen and (max-width: 970px) {
    height: 50vh;

    width: 80vw;
  }
`;
export const Image11 = styled.img`
  height: 60vh;
  width: 23vw;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 2.5px grey;

  @media screen and (max-width: 970px) {
    height: 40vh;

    width: 40vw;
  }
`;
export const Image4 = styled.img`
  height: 40vh;
  width: 15vw;
  box-shadow: 0px 0px 5px 2.5px grey;

  border-radius: 2px;
  @media screen and (max-width: 970px) {
    height: 25vh;
    width: 33vw;
  }
`;
export const Image44 = styled.img`
  height: 40vh;
  width: 23vw;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 2.5px grey;

  @media screen and (max-width: 970px) {
    height: 25vh;

    width: 45vw;
  }
`;
export const Image3 = styled.img`
  height: 23vh;
  width: 30vw;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 2.5px grey;

  @media screen and (max-width: 970px) {
    height: 30vh;

    width: 80vw;
  }
`;
export const Image33 = styled.img`
  height: 30vh;
  width: 25vw;
  box-shadow: 0px 0px 5px 2.5px grey;

  border-radius: 2px;
  @media screen and (max-width: 970px) {
    height: 25vh;

    width: 50vw;
  }
`;
export const Space = styled.div`
  width: 20px;
  height: 20px;
  @media screen and (max-width: 970px) {
    width: 0px;
    height: 15px;
  }
`;
export const BSpace = styled.div`
  background-color: black;
  @media screen and (max-width: 970px) {
    width: 100%;
    height: 50px;
  }
`;
export const Divider = styled.div`
  width: 0;
  height: 0;
  @media screen and (max-width: 970px) {
    display: flex;
    align-items: center;
    align-content: center;
    margin: auto;

    text-align: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    height: 3px;
    background-color: grey;
  }
`;
export const Regimg = styled.img`
  height: 50vh;
  width: 40vw;
  box-shadow: 0px 0px 5px 2.5px grey;

  border-radius: 2px;
  @media screen and (max-width: 970px) {
    margin-top: 100px;
    height: 25vh;

    width: 80vw;
  }
`;
