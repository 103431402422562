import React, { useEffect, useState } from "react";
import {
  Backgroundimg,
  Head,
  Image1,
  Image2,
  Image3,
  Lefttop,
  Mvideo,
  Pherosection,
  Rightbottom,
  Space,
  Row,
  Underline,
  Image4,
  Image44,
  Image33,
  Image22,
  Heroimg,
  BSpace,
  Divider,
} from "./SportsConsultingDepartmentsstyles";
import hero from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";

import img1 from "../components/assets/premiere2-1024x591.jpg";
import img2 from "../components/assets/IMG-20221211-WA0068.jpg";
import img3 from "../components/assets/IMG-20221211-WA0071.jpg";
import img4 from "../components/assets/IMG-20221211-WA0037.jpg";
import img5 from "../components/assets/IMG-20221211-WA0039.jpg";
import img6 from "../components/assets/Arsenal Club 2(1).jpg";
import img7 from "../components/assets/302_alta_montanyahotel Aerea(2).jpg";
import img8 from "../components/assets/IMG-20221211-WA0062.jpg";
import img9 from "../components/assets/IMG-20221211-WA0069.jpg";
import img10 from "../components/assets/Arsenal Club 3(1).jpg";
import img11 from "../components/assets/Arsenal Club 4(1).jpg";
import img12 from "../components/assets/IMG-20221211-WA0055.jpg";
import img13 from "../components/assets/IMG_6877.jpg";
import img14 from "../components/assets/IMG_6336(1).jpg";
import img15 from "../components/assets/file3-1.jpeg";
import img16 from "../components/assets/file-1.jpeg";
import img17 from "../components/assets/file2-1.jpeg";

function PremierCamps() {
  var descA = [
    "معسكرات متميزة",
    "معسكر في مدينة برشلونة يتم إعداده وتصميمه حسب رغبة الأندية والمنتخبات من ناحية العمر والمستوى, نقوم بالاستماع الى احتياجاتك وهدفك من المعسكر وتحليلها لتصميم المعسكر التدريبي المناسب لك ولهدفك ",
    " المعسكر يتضمن",
    " سيتكفل فريق عملنا بكل ما يتعلق بالمعسكر الرياضي منذ لحظة الوصول حتى المغادرة ",
    " ملاعب العشب الطبيعي",
    " السكن المخصص للاعبي كرة القدم مما يؤمن الراحة والاسترخاء الكامل",
    "  وسائل النقل الخاصة بتغطية جميع تنقلات الفريق  ",
    "تنظيم المباريات الودية ",
    "الوجبات الغذائية الكاملة والخاصة للرياضيين والمحترفين حسب الشريعة الإسلامية ",
    "تنظيم المباريات الودية مع الأندية الكتالونية والإسبانية حسب مستوى الفريق",
    "تنظيم الأنشطة",
    " تنظيم الأنشطة الترفيهية والسياحية والرياضية في مدينة برشلونة",
    "الصالات الرياضية و القاعات المجهزة ",
    "  توفير جميع أنواع الصالات الرياضية وقاعات الاجتماعات المجهزة باحدث التقنيات ",
    "الفحوصات الطبية",
    "إجراء الفحوصات الطبية المتضمنة التقييم الفيزيولوجي والعصبي والعضلي والحالة البدنية لكل لاعب بشكل فردي، وتقديم التقرير الكامل عن حالة كل لاعب",
    " تقديم الاستشارات بكل ما يتعلق بتدريب كرة القدم من قبل الفنيين المختصين في تدريب أفضل الفرق والأندية الإسبانية والأوربية",
    "الاستشارات الطبية و الرياضية",
    " الاستشارات الطبية من قبل فريق عملنا الطبي المختص في تشخيص وعلاج الإصابات الأكثر شيوعاً في كرة القدم",
    " خدمة إعادة التأهيل والوقاية من الإصابة، حيث يقوم فريق العلاج الفيزيائي بتقديم التدريب المناسب لتقليل مخاطر الإصابة وعلاجها باستخدام أفضل طرائق العلاج العالمية",
  ];
  var descE = [
    "Premier Camps",
    "Our camps are designed according to the needs and goals of the clubs and teams, based on their ages and levels. Let us know what you are looking for so we can design the perfect training camp for you in the city of Barcelona.",
    "Camp includes",
    "Our team will take care of everything related to the sports camp from arrival to departure",
    " natural grass field",
    " comfortable and relaxing accommodation for players ",
    " transportation for all team movements",
    "Friendly matches",
    "complete and special meals for athletes and professionals according to Islamic law",
    "friendly matches with Catalan and Spanish clubs, according to the team level",
    "Organizing Activities",
    " leisure, tourism, and sports activities in the city of Barcelona",
    "Gyms and meeting rooms",
    "Providing all kinds of gyms and meeting rooms equipped with the latest technology",
    "Medical Examinations",
    " medical examinations that include a physiological, neurological and muscular evaluation and a detailed report on the physical condition of each player",
    "advice from technicians specialized in training the best Spanish and European teams and clubs",
    "Medical , Sport Consultations",
    " medical consultations from our specialized medical team in diagnosing and treating the most common injuries in football",
    "rehabilitation and injury prevention services by our physiotherapy team with the best international treatment methods",
  ];
  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);
  return (
    <div>
      {" "}
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[0]}</Head>
          <Underline />
          {data[1]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Image1 src={img1} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[2]}</Head>
          <Underline />
          {data[3]} <br /> <br /> {data[4]} <br /> <br /> {data[5]} <br />{" "}
          <br /> {data[6]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img2} />
            </Row>
          </Row>
          <Space />
          <Row>
            <Row>
              <Image2 src={img3} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[7]}</Head>
          <Underline />
          {data[8]} <br /> <br /> {data[9]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img4} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image2 src={img5} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[10]}</Head>
          <Underline />
          {data[11]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image44 src={img6} /> <Space />
              <Image44 src={img8} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image44 src={img7} /> <Space />
              <Image44 src={img9} />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[12]}</Head>
          <Underline />
          {data[13]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image3 src={img10} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image3 src={img11} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image3 src={img12} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[14]}</Head>
          <Underline />
          {data[15]} <br /> <br /> {data[16]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img13} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image2 src={img14} />
            </Row>
          </Row>{" "}
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[17]}</Head>
          <Underline />
          {data[18]}
          <br /> <br /> {data[19]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image33 src={img15} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image33 src={img16} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image33 src={img17} /> <Space />
            </Row>
          </Row>{" "}
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <BSpace />
      <BSpace />
    </div>
  );
}

export default PremierCamps;
