import React from "react";
import { useState, useEffect } from "react";
import { Heads, Iconn, Name } from "../Servicos/Servciosstyles";
import icon from "../assets/icons8-tournament-64 (1).png";
import hero from "../assets/c31a51da7dd939423c2eedbf7e726765.jpg";
import { useHistory } from "react-router-dom";

import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import {
  Column,
  Servicesbox,
  ServicesHead,
  Servicesimg,
  Servicess,
  Servicestexts,
  Servicestextx,
  Spacemain,
} from "./Servicesstyles";
import img1 from "../assets/t2024.jpg";
import img2 from "../assets/MEDIA.jpg";
import img3 from "../assets/FB_IMG_1704400244389.jpg";
import { Button, Container, Heading, TextWrapper } from "../../globalStyles";
import { Blur, ContentButton, ContentColumn } from "../Content/ContentStyles";
import { Heroimg, Space } from "../../pages/SportsConsultingDepartmentsstyles";
import styled from "styled-components";

export const Vid = styled(motion.iframe)`
  width: 100%;
  height: 40vh;
  border-radius: 2px;
  box-shadow: -10px -10px 2px grey;

  @media screen and (max-width: 970px) {
    height: 100%;
    width: 100%;
  }
`;
function Services() {
  var serA = [" البطولات", "بطولة 2024", "معرض البطولات", "نتائج بطولة 2023"];
  var serE = [
    "TOURNAMENTS",
    "TOURNAMENT 2024",
    "Tournaments Gallery",
    "Results 2023",
  ];
  var desA = [
    "معسكر تدريبي لكرة القدم بالإضافة إلى المشاركة في أقوى وأكبر بطولة في إسبانيا.",
    "  معارك شديدة ومنافسات شرسة ومجد نهائي: البطولة - مشهد مثير.",
    "معارك مثيرة. مفاجآت مفاجئة. بطل جديد يظهر في بطولة 2023.",
    "المزيد",
  ];
  var desE = [
    "A Football Training Camp In Addition To Participating The Strongest And Largest Championship In Spain .",

    "Intense battles, fierce rivalries, and ultimate glory: The Tournament - a thrilling spectacle. ",

    "Thrilling battles. Surprise upsets. A new champion rises in the 2023 Tournament.",
    "Find More",
  ];
  let history = useHistory();

  const [sers, setSers] = useState(serE);
  const [descs, setDescs] = useState(desE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const opentab = (url) => {
    history.push(url); // Here you have to pass the route where you want to redirect
  };
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setSers(serA);
      setDescs(desA);
    } else {
    }
  };
  const initial = { opacity: 0, y: 50 };
  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    checklng();
  }, []);
  useEffect(() => {
    if (inView) {
      console.log("sss");
      animation.start({
        opacity: 1,
        y: 1,
      });
    }
  }, [inView, animation]);
  return (
    <>
      <Servicess ref={ref}>
        <Heroimg src={hero} />
        <ServicesHead>
          <Iconn src={icon}></Iconn> {sers[0]}
        </ServicesHead>
        <Column>
          {" "}
          <Heroimg src={hero} />
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/TORNAMENT2024");
              }}
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              whileHover={{ rotate: 2, scale: 1.02 }}
              src={img1}
            ></Servicesimg>
            <Space />
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[1]}
                <Servicestexts>{descs[0]}</Servicestexts>
              </Servicestextx>{" "}
            </motion.div>
          </Servicesbox>
          <Servicesbox
            onClick={() => {
              opentab("/MEDIA");
            }}
          >
            <Vid
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              whileHover={{ rotate: 2, scale: 1.02 }}
              src="https://www.youtube.com/embed/6Dkz3vqDMVE"
              title="Off Season 2022 (Nico González)"
              frameborder="0"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            ></Vid>{" "}
            <Space />{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[2]}
                <Servicestexts>{descs[1]}</Servicestexts>
              </Servicestextx>
            </motion.div>
          </Servicesbox>
          <Servicesbox>
            <Servicesimg
              onClick={() => {
                opentab("/RESULTS");
              }}
              whileHover={{ rotate: 2, scale: 1.02 }}
              initial={initial}
              transition={{ delay: 0.1, duration: 0.6 }}
              animate={animation}
              src={img3}
            ></Servicesimg>{" "}
            <Space />{" "}
            <motion.div
              initial={initial}
              transition={{ delay: 0.4, duration: 0.6 }}
              animate={animation}
            >
              <Servicestextx>
                {sers[3]}
                <Servicestexts>{descs[2]}</Servicestexts>
              </Servicestextx>
            </motion.div>
          </Servicesbox>
        </Column>{" "}
        <Blur></Blur>
      </Servicess>
    </>
  );
}

export default Services;
