import React, { useEffect, useState } from "react";
import {
  Backgroundimg,
  Head,
  Image1,
  Image2,
  Image3,
  Lefttop,
  Mvideo,
  Pherosection,
  Rightbottom,
  Space,
  Row,
  Underline,
  Image4,
  Image44,
  Image33,
  Heroimg,
  Image22,
  BSpace,
  Divider,
} from "./SportsConsultingDepartmentsstyles";
import hero from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";

import img1 from "../components/assets/IMG_6280(1).jpg";
import img2 from "../components/assets/IMG-20221126-WA0014.jpg";
import img3 from "../components/assets/IMG-20221126-WA0019.jpg";
import img4 from "../components/assets/IMG-20221126-WA0010.jpg";
import img5 from "../components/assets/IMG-20221126-WA0015.jpg";
import img6 from "../components/assets/IMG-20221126-WA0018.jpg";
import img7 from "../components/assets/IMG-20221211-WA0038.jpg";

function EliteIndividual() {
  var descA = [
    "معسكر نخبوي فردي",
    "نقدم للاعب خدمة استشارية فردية متخصصة ومستقلة تهدف لتحسين أداء اللاعب وتطوير المهارات الفردية والحالة البدنية والنفسية، وتقليل عوامل الإصابة العصبية والعضلية من خلال معدات متطورة للتشخيص الطبي الرياضي والعلاج الفيزيائي غير الدوائي وإعادة التأهيل",
    "ما يشمله هذا البرنامج",
    "التقييم العصبي والعضلي والبنيوي والفيزيولوجي ",
    "	خطة تدخل للتقليل من مخاطر الاصابة",
    "التدريب البدني و الاستشارات",
    "تدريب بدني فردي تنسيقي في الصالة الرياضية والملعب",
    "استشارات اللاعبين وتدريب أون لاين حسب حاجة اللاعب",
  ];
  var descE = [
    "Elite Individual",
    "We offer each elite player a specialized and personal consultation. This aims to improve the player’s performance, develop their individual skills, assess their physical and psychological condition, and prevent neurological and muscular injuries by using advanced sports medicine techniques, physiotherapy, and rehabilitation.",
    "The Elite Individual program includes:",
    "neuromuscular, structural, and physiological evaluation ",
    " intervention plan to reduce the risk of injury",
    "Physical training , Consultations ",
    "coordination of personal physical training at the gym and on the pitch  ",
    "consultations and online training based on the player’s individual needs",
  ];
  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);
  return (
    <div>
      {" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[0]}</Head>
          <Underline />
          {data[1]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img1} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image2 src={img2} />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} /> <Space />
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image33 src={img3} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image33 src={img4} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image33 src={img5} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[2]}</Head>
          <Underline />
          {data[3]}
          <br />
          <br /> <br /> {data[4]}
        </Lefttop>
      </Pherosection>{" "}
      <Divider />{" "}
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[5]}</Head>
          <Underline />
          {data[6]}
          <br /> <br /> <br /> {data[7]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image44 src={img6} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image2 src={img7} />
            </Row>
          </Row>
        </Rightbottom>{" "}
      </Pherosection>{" "}
      <BSpace /> <BSpace /> <BSpace />
    </div>
  );
}

export default EliteIndividual;
