import styled from "styled-components";
import { Button } from "../../globalStyles";
import { keyframes } from "styled-components";

export const HeroSection = styled.section`
  height: 100vh;

  background-position: center;
  background-size: cover;

  padding-top: clamp(70px, 25vh, 220px);
  box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);

  @media screen and (max-width: 970px) {
    height: 42vh;
  }
`;
export const Icon = styled.svg`
  width: 20px;
  height: 20px;
`;

export const HeroVideo = styled.video`
  object-fit: fill;
  width: 100%;
  height: 102vh;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
  top: 0;
  position: absolute;
  z-index: -1;
  @media screen and (max-width: 970px) {
    object-fit: fill;
    height: 48vh;
  }
`;

export const HeroText = styled.p`
  font-size: clamp(0.9rem, 1.5vw, 1.3rem);
  line-height: 24px;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
`;
const rotate = keyframes`
from{    transform: scale3d(0, 0, 0);
},
  to {
    transform: scale3d(2, 2, 2);
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: wrap;
  margin-top: 33%;

  @media screen and (max-width: 700px) {
    margin-top: 35%;
  }
  gap: 0.8rem;
`;
export const Iconnn = styled.img`
  padding-right: 5px;
  animation: ${rotate} 3s linear;
  cursor: pointer;
  height: 45px;
  width: 100px;
`;

export const HeroButton = styled(Button)`
  color: black;

  &:before {
    background: #fff;
    height: 500%;
  }

  &:hover:before {
    height: 0%;
  }

  &:hover {
    color: white;
  }
`;
