import logo from '../assets/logo.png'

export const heroOne = {

	topLine: {
		text: 'Founded in 2022',
	},
	headline: "MORE THAN SPORT",
	description: 'La excelencia como optimizador del rendimiento Ofrecemos asesoría especializada e independiente a deportistas, clubs, federaciones y empresas del sector con el fin de encontrar soluciones a  sus necesidades. Nuestra metodología se sustenta en el conocimiento científico, en la tecnología innovadora, en la experiencia y las habilidades de nuestros profesionales.',
	buttonLabel: 'Find More',
	imgStart: 'start',
	img:logo,
	start: 'true',
	reverse: false,

};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Designed & Developed',
	},
	headline: 'The best practices',
	description: 'Our clients have had the best experience working with us',
	buttonLabel: 'View Project',

	linkTo: '/more',
	imgStart: 'start',
	img: './assets/svg/Untitled (1).svg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Highly reputed brand',
	},
	headline: 'Why us? ',
	description:
		'Our 9 year experience have allowed us to use the most innovative technologies and methodologies',
	buttonLabel: 'View Project',

	linkTo: '/download',
	imgStart: '',
	img: './assets/svg/Untitled (12).svg',
	start: 'true',
};
