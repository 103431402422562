import React from "react";
import { Content } from "../components/Content/Content";
import { heroOne, heroTwo, heroThree } from "../data/HeroData";
import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import Servcios from "../components/Servicos/Servcios";
import Media from "../components/Media/Media";
import Services from "../components/Services/Services";
import Camp from "../components/Camp/Camp";
import Tourment from "../components/Services/Tourment";

const Home = () => {
  return (
    <>
      <Hero />
      <Content {...heroOne} />
      <Tourment />
      <Services />
      <Camp />
      <Media />
    </>
  );
};

export default Home;
