import React, { useEffect, useState } from "react";

import {
  Backgroundimg,
  Head,
  Image1,
  Image2,
  Image3,
  Lefttop,
  Mvideo,
  Pherosection,
  Rightbottom,
  Heroimg,
  Space,
  Row,
  Underline,
  Divider,
} from "./SportsConsultingDepartmentsstyles";
import img1 from "../components/assets/IMG-20221126-WA0018.jpg";
import img3 from "../components/assets/IMG-20221231-WA0014.jpg";
import img4 from "../components/assets/IMG-20221231-WA0027.jpg";
import img5 from "../components/assets/IMG-20221231-WA0015.jpg";
import img6 from "../components/assets/IMG-20221231-WA0024.jpg";
import img7 from "../components/assets/IMG_6336(1).jpg";
import img8 from "../components/assets/IMG_6877.jpg";
import img9 from "../components/assets/IMG-20221231-WA0023.jpg";
import img10 from "../components/assets/IMG-20221231-WA0022.jpg";
import img11 from "../components/assets/IMG-20221231-WA0020.jpg";
import img12 from "../components/assets/IMG-20221231-WA0021.jpg";
import img13 from "../components/assets/IMG-20221231-WA0019.jpg";
import hero from "../components/assets/c31a51da7dd939423c2eedbf7e726765.jpg";

import {
  ContentButton,
  ContentColumn,
  Img,
} from "../components/Content/ContentStyles";

function SportsConsultingDepartments() {
  var descE = [
    "Sports Consulting",
    " We develop business plans to help build the brand identity of your club or academy to become more recognizable. During the initial sportsaudit, we will identify the strengths and areas of improvement of your club, looking at six different dimensions: methodology, strength training, strength conditioning, sports medicine, technological innovation, and exploration. We evaluate and review each action plan   against SMART KPIs so that our plans match your desired goals within your specified timeframes.",
    "Football Methodology Department",
    "Our game methodology is based on a comprehensive study of the current coaching staff and players. We analyze the methodology, game plan, and their application with assistance from the coaching staff. We then work with the players to develop their skills and bring them to the top of the field.",
    "Strength Training Department",
    "We analyze the current fitness and strength of the coaches and provide them with information, plans, and methods. They can then use these on and off the field to help prevent sports injuries and to develop the physical strength, fitness, speed, and agility of the players.",
    "Sports Medicine Department",
    "Our specialized team holds the highest international degrees in sports medicine and has extensive experience working with the largest European and international clubs. Our medical team accurately diagnoses and evaluates each injury in order to develop an effective action plan so that the player can return to the field in good condition as quickly as possible.",
    "Technology Department",
    "Our team uses the latest technologies in both the sports and football world. We provide all the hardware, software, and applications needed and employ qualified technicians to achieve the best results",
    "Scout Analysis Department",
    "Our scouting team studies both the teams and their players through video recordings or direct observation of games. Their goal is to use the collected data to improve the team’s overall strategies against different opponents. Data collected on each player’s strengths and abilities are used by the coaching staff to develop appropriate plans for practice and games.",
  ];
  var descA = [
    "استشارات رياضية",
    "سوف نضع خطة العمل الكاملة لنساعدك على تطوير هوية ناديك أو أكاديميتك بحيث يمكن التعرف عليها والاعتراف بها  بناءً على التدقيق الرياضي الأولي نحدد نقاط الضعف والقوة في ستة مجالات، المنهجية وتدريب القوة والتكيف والطب الرياضي والابتكار التكنولوجي والاستكشاف   سوف نعتمد تقييم ومراجعة خطة العمل باستخدام SMART KPIs لتحقيق الأهداف المرادة بالفترة الزمنية المحددة  ",
    " قسم منهجية كرة القدم",
    "نعتمد منهجية لعب معينة اعتماداً على الدراسة الكاملة للكادر التدريبي الحالي الموجود وميزات اللاعبين الحاليين والعمل على فهم المنهجية وخطة اللعب وتطبيقها، بدعم ومساعدة الكادر التدريبي بكل ما يتعلق بتنفيذ منهجية اللعب على أرض الملعب، ومن ثم العمل على اللاعبين لتطويرهم وإيصالهم إلى قمة العطاء والمستوى المطلوب في أرض الملعب ",
    "قسم القوة والتكييف",
    "نقوم بدراسة وتحليل الوضع الحالي لمدربي اللياقة والقوة البدنية، ونعمل على تجهيزهم وتزويدهم بكافة المعلومات والخطط وطرائق العمل لتطبيقها في أرض الملعب وخارجه، وذلك للتخفيف من الإصابات الرياضية للاعبين وتطوير قوتهم البدنية واللياقة والسرعة والرشاقة لديهم",
    "قسم الطب الرياضي",
    "فريق عملنا المختص والحاصل على أعلى الشهادات العالمية في الطب الرياضي وصاحب الخبرة الكبيرة في العمل مع أكبر الأندية الأوربية والعالمية   سيقوم فريقنا الطبي بتشخيص حالة الإصابات وتقييمها بشكل دقيق، لوضع خطة العمل الفعالة لمعالجتها بأسرع وقت ممكن وعودة اللاعب إلى أرض الملعب بأفضل حالاته ",
    "قسم التقني و التكنولوجي",
    "يقوم فريقنا باستخدام أحدث ما توصلت إليه التكنولوجيا في عالم الرياضة بشكل عام وكرة القدم بشكل خاصنقوم بتقديم كل ما يتعلق بالتكنولوجيا من أجهزة و برامج وتطبيقات، ونحرص على تأهيل القائمين عليها للحصول على أفضل النتائج",
    "قسم التحليل و الأستكشاف",
    "يقوم فريقنا المتخصص بالاستكشاف والاستطلاع لدراسة الفرق بشكل عام واللاعبين بشكل خاص، عن طريق جمع المعلومات من خلال مقاطع الفيديو أو المراقبة المباشرة للمباريات، والهدف من ذلك هو تحويل البيانات التي تم جمعها إلى أفكار لتعزيز استراتيجيات الفريق ومعرفة أفضل طريقة لمواجهة الخصم، بالإضافة إلى ذلك ستساعد هذه المعلومات في معرفة ميزات اللاعبين وإمكانياتهم على الصعيد الفردي مما يسهل للكادر التدريبي وضع الخطة المناسبة لكل تدريب ومباراة",
  ];
  const [data, setDatas] = useState(descE);
  const [lng, setlng] = useState(localStorage.getItem("lng"));
  const checklng = () => {
    if (lng == "ar") {
      console.log("sssssss");
      setDatas(descA);
    } else {
    }
  };
  useEffect(() => {
    checklng();
  }, []);

  return (
    <div>
      <Pherosection>
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head>{data[0]}</Head>
          <Underline />
          {data[1]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Image1 src={img1} />
          </Row>
        </Rightbottom>
      </Pherosection>{" "}
      <Divider />
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Rightbottom>
          <Row>
            <Row>
              <Image2 src={img3} />
            </Row>
          </Row>
          <Space />
          <Row>
            <Row>
              {" "}
              <Image2 src={img4} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[2]}</Head>
          <Underline />
          {data[3]}
        </Lefttop>
      </Pherosection>{" "}
      <Divider />
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[4]}</Head>
          <Underline />
          {data[5]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img5} />
            </Row>
          </Row>
          <Space />
          <Row>
            <Row>
              <Image2 src={img6} /> <Space />
            </Row>
          </Row>
        </Rightbottom>
      </Pherosection>{" "}
      <Divider />
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img7} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image2 src={img8} /> <Space />
            </Row>
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[6]}</Head>
          <Underline />
          {data[7]}
        </Lefttop>
      </Pherosection>{" "}
      <Divider />
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Lefttop>
          <Head> {data[8]}</Head>
          <Underline />
          {data[9]}
        </Lefttop>
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image2 src={img9} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image2 src={img10} /> <Space />
            </Row>
          </Row>
        </Rightbottom>
      </Pherosection>{" "}
      <Divider />
      <Pherosection>
        {" "}
        <Heroimg src={hero} />{" "}
        <Rightbottom>
          <Row>
            <Row>
              <Space /> <Image3 src={img11} />
            </Row>
          </Row>{" "}
          <Space />
          <Row>
            <Image3 src={img12} />
          </Row>{" "}
          <Space />
          <Row>
            <Row>
              <Image3 src={img13} /> <Space />
            </Row>{" "}
          </Row>
        </Rightbottom>{" "}
        <Lefttop>
          <Head> {data[10]}</Head>
          <Underline />
          {data[11]}
        </Lefttop>
      </Pherosection>
    </div>
  );
}

export default SportsConsultingDepartments;
